'use strict';
class BeSearchTableController {
  constructor($scope, Util) {
    this.searchString = ""
    this.isLoading = true;
    this.sortField = $scope.$parent.initialSort;
    this.sortDesc = $scope.$parent.sortDesc;
    this.limit = $scope.$parent.limit || 20;
    this.skip = $scope.$parent.skip || 0;
    this.defaultPageNumber = $scope.$parent.defaultPageNumber || 3
    this.currentPage = (this.skip/this.limit)+1;
    this.total = this.defaultPageNumber * this.limit;
    this.Util = Util;
    this.$scope = $scope
    this.endpoint = $scope.$parent.endpoint;

    if(this.endpoint) {
      const sortDirection = (this.sortDesc) ? "desc" : "asc";
      this.endpoint(this.searchString, this.sortField, sortDirection, this.limit, this.skip).then((data) => {
        this.$scope.onDataChanged(data)
        this.isLoading = false
      }).catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des livraisons');
      });
    }
  }
  getObjects() {
    return this.$scope.objects;
  }


  getObjectValue(object, col) {
    if (col.filter === 'date') {
      return this.Util.getDateString(object[col.value]) + " " + object[col.value].getFullYear();
    } else if (col.filter === "datetime") {
      return this.Util.getDateTimeString(object[col.value]);
    } else if(col.filter === "num") {
      return Number(object[col.value]).toLocaleString();
    }else {
      return object[col.value];
    }
  }

  onSortChange(field) {
    this.sortDesc = !this.sortDesc;
    this.sortField = field;
    const sortDirection = (this.sortDesc) ? "desc" : "asc";
    this.isLoading = true;
    this.endpoint(this.searchString, this.sortField, sortDirection,this.limit, this.skip).then( (data) => {
      this.$scope.onDataChanged(data)
      this.isLoading = false

    }).catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu lors du chargement des livraisons');
    });
  }
  onPageChange() {
    this.skip = (this.currentPage -1) * this.limit
    const sortDirection = (this.sortDesc) ? "desc" : "asc";
    this.isLoading = true;
    this.endpoint(this.searchString, this.sortField, sortDirection,this.limit, this.skip).then( (data) => {
      if(data.length === this.limit && (this.total/this.currentPage) === this.limit) {
        this.total +=  this.limit
      }
      this.$scope.onDataChanged(data)
      this.isLoading = false

    }).catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu lors du chargement des livraisons');
    });
  }


  onTypingEnd() {
    this.skip = 0;
    this.currentPage = 1
    this.isLoading = true;
    this.endpoint(this.searchString, this.sortField, "ASC",this.limit, this.skip).then( (data) => {
      this.total = (data.length === this.limit) ?  this.limit * 2 : data.length;
        this.$scope.onDataChanged(data)
      this.isLoading = false;

    })
  }
  get searchFieldHolderClass() {
    if (!this.$scope.buttons) return "col-xs-12";
    const rowCount = 12 - (2 * this.$scope.buttons.length);
    return `col-xs-${rowCount}`;
  }
}
angular.module('cpformplastApp')
  .controller('BeSearchTableController', BeSearchTableController);
